const siteMetadata = {
  siteUrl: "https://www.hym.club",
  siteLang: "en-us", // This should be the same as Prismic master locale
  title: "Hellenic Yacht Masters' Club",
  shortTitle: "HYMC",
  description: "Hellenic Yacht Masters' Club",
  logoUrl: "https://hymc.thinkplus.dev/uploads/blue_logo_b7079e2e24.svg",

  // Values used in manifest
  siteBackgroundColor: "#fff",
  siteThemeColor: "#000",
  siteIcon: "./src/assets/home/logo-hymc.svg",

  // Image Details used as fallback at metadata
  defaultImage: "https://hymc.thinkplus.dev/uploads/blue_logo_b7079e2e24.svg",
  defaultImageWidth: "512",
  defaultImageHeight: "512",
  defaultImageAlt: "hymc-logo",

  // Business Information
  BusinessName: "Hellenic Yach Masters' Club",
  BusinessAddress: "107 Naiadon Str, and Areos Str",
  BusinessCity: "Athens",
  BusinessRegion: "Palaio Faliro",
  BusinessPostalCode: "17562",
  BusinessCountry: "Greece",
  BusinessPhoneNumber: "+302108947049",
  BusinessEmail: "info@hym.club",
};

module.exports = {
  siteMetadata,
};
