import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from "../components/meta/Seo";
import BlogPostContent from '../components/blog/BlogPostContent';
import NewsPostContent from '../components/blog/NewsPostContent';


export default function SinglePostTemplate({ data }) {
  return (
    <Layout>
      <Seo
        page={data.strapiPost}
        curPage={data.strapiPost.Title}
        pageType={"post"}
      />
      {data.strapiPost.isNews ? (
        <NewsPostContent data={data} />
      ) : (
        <BlogPostContent data={data} />
      )}
    </Layout>
  );
}

export const query = graphql`
  query SinglePostQuery($postId: String) {
    strapiPost(id: { eq: $postId }) {
      isNews
      SourceLink
      Title
      slug
      Meta {
        PageMetaDescription
        PageMetaTitle
        PageMetaImage {
          width
          height
          url
          localFile {
            url
          }
          alternativeText
        }
      }
      Builder {
        ... on STRAPI__COMPONENT_BUILDER_IMAGE {
          strapi_component
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_BUILDER_TEXT {
          strapi_component
          Text {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
         ... on STRAPI__COMPONENT_BUILDER_IMAGE_GRID {
          strapi_component
          Images {
            localFile {
              childImageSharp {
                id
                gatsbyImageData
              }
            }
          }
          perRow
        }
      }
      FeaturedImage {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        width
        height
        url
        alternativeText
      }
      FeaturedImageMobile {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        width
        height
        url
        alternativeText
      }
      publishedAt
      PublicationDate(formatString: "DD/MM/YYYY")
    }
    allStrapiPost(
      limit: 4,
      sort: { fields: publishedAt, order: DESC }
      filter: {isNews: {ne: true}}
    ) {
      nodes {
        slug
        Title
        id
        isFeatured
        FeaturedImage {
          localFile {
            url
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
    newsPosts: allStrapiPost(
      limit: 4,
      sort: { fields: publishedAt, order: DESC }
      filter: {isNews: {eq: true}}
    ) {
      nodes {
        slug
        publishedAt
        PublicationDate(formatString: "DD/MM/YYYY")
        Title
        id
        isFeatured
        FeaturedImage {
          localFile {
            url
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
