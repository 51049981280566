import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react'

import { siteMetadata } from '../../../config/site-config';
import { Link } from 'gatsby';

const rows = {
  "Two": "lg:grid-cols-2 gap-8",
  "Three": "lg:grid-cols-3 gap-6",
  "Four": "lg:grid-cols-4 gap-4",
}

const NewsPostContent = ({ data }) => {
  const {
    strapiPost: { Builder, FeaturedImage, FeaturedImageMobile, SourceLink, slug, PublicationDate },
  } = data;

  const url = new URL(`/news/${slug}`, siteMetadata.siteUrl);
  const encodedUrl = encodeURIComponent(url);

  return (
    <section className="border-t-[211px] lg:border-t-[300px] border-hymcBlue">
      <div className="posts-page h-fit w-[95%] md:w-[80%] lg:w-[1080px] mx-auto">
        <div className="posts-hero-section relative flex mt-24">
          {FeaturedImage && (
            <GatsbyImage
              image={getImage(FeaturedImage.localFile)}
              alt={data.strapiPost.Title || ""}
              className={`mx-auto ${FeaturedImageMobile ? "dsk" : ""}`}
              objectFit="contain"
              objectPosition="center"
            />
          )}
          {FeaturedImageMobile && (
            <GatsbyImage
              image={getImage(FeaturedImageMobile.localFile)}
              alt={data.strapiPost.Title || ""}
              className={`mx-auto ${FeaturedImage ? "mb" : ""}`}
              objectFit="contain"
              objectPosition="center"
            />
          )}
        </div>
        <div className="quote-section px-4 lg:px-6 pb-28 pt-20 lg:pt-28">
          <div className="quote-section-contents max-w-[1400px]">
            {PublicationDate && (
              <p className='w-full mb-3'>
                {PublicationDate}
              </p>
            )}
            <h2 className="title font-roboto mb-16 lg:mb-20 leading-tight text-hymcBlue lg:min-w-[450px]">
              {data.strapiPost.Title}
            </h2>
            <div className="body-text builder flex flex-wrap items-center justify-center gap-x-8 gap-y-16 lg:min-w-[450px] mb-20">
              {Builder &&
                Builder?.map((section) => {
                  switch (section.strapi_component) {
                    case "builder.text":
                      return (
                        <div
                          className="[&_*]:!font-roboto text-section leading-2 flex flex-col gap-y-4"
                          dangerouslySetInnerHTML={{
                            __html:
                              section?.Text?.data?.childMarkdownRemark?.html,
                          }}
                        />
                      );
                    case "builder.image":
                      return (
                        <GatsbyImage
                          image={getImage(section?.Image?.localFile)}
                          alt={"Post image"}
                          className="image-section max-h-[500px]"
                          objectFit="contain"
                        />
                      );
                    case "builder.image-grid":
                      return (
                        <div className={(`grid grid-cols-1 ${rows[section?.perRow || "Two"]}`)}>
                          {section?.Images.map((image) => {
                            return <GatsbyImage
                              key={image?.id}
                              image={getImage(image?.localFile)}
                              alt={""}
                            />
                          })}
                        </div>
                      );
                    default:
                      return null;
                  }
                })}
            </div>
            {SourceLink && (
              <div className='mb-12'>
                <a href={SourceLink} target="_blank" rel="noopener noreferrer" className='block capitalize pl-2 py-3 text-lg tracking-widest font-semibold border-l-[14px] border-hymcBlue'>
                  {new URL(SourceLink).hostname?.replace("www.", "")}
                </a>
              </div>
            )}
            <ul className='flex items-center gap-6 pb-10 border-b border-black'>
              <li>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}>
                  <svg className='flex-shrink-0' width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.19387 21.3477H7.06013V11.7717H10.254L10.7583 8.07566H7.06013V5.72366C7.06013 4.63166 7.39633 3.87566 8.90922 3.87566H10.8424V0.515656C10.5062 0.431656 9.32946 0.347656 7.98468 0.347656C5.127 0.347656 3.19387 2.11166 3.19387 5.21966V7.99166H0V11.6877H3.19387V21.3477Z" fill="#015794" />
                  </svg>
                </a>
              </li>
              <li>
                <a href={`https://x.com/intent/tweet?url=${encodedUrl}`}>
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_2150_391)">
                      <mask id="mask0_2150_391" style={{ maskΤype: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                        <path d="M0 0H21V21H0V0Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_2150_391)">
                        <path d="M16.5375 0.984375H19.758L12.723 9.04538L21 20.0164H14.52L9.441 13.3639L3.636 20.0164H0.4125L7.9365 11.3914L0 0.985875H6.645L11.229 7.06537L16.5375 0.984375ZM15.405 18.0844H17.19L5.67 2.81587H3.756L15.405 18.0844Z" fill="#015794" />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_2150_391">
                        <rect width="21" height="21" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                </a>
              </li>
              <li>
                <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`}>
                  <svg className='flex-shrink-0' width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.7394 7.02734H0.337402V21.0799H4.7394V7.02734Z" fill="#015794" />
                    <path d="M15.8307 6.6875C13.7143 6.6875 12.2752 7.87265 11.6826 8.97315H11.598V7.02611H7.44995V21.0787H11.7673V14.137C11.7673 12.2747 12.1059 10.4969 14.3916 10.4969C16.5926 10.4969 16.6772 12.6133 16.6772 14.2217V21.0787H20.9946V13.3751C21.0792 9.56572 20.2327 6.6875 15.8307 6.6875Z" fill="#015794" />
                    <path d="M2.53961 5.07923C3.89408 5.07923 5.07923 3.97873 5.07923 2.53961C5.07923 1.18515 3.97873 0 2.53961 0C1.1005 0 0 1.1005 0 2.53961C0 3.97873 1.18515 5.07923 2.53961 5.07923Z" fill="#015794" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="more-articles mb-32">
          <Link to="/news" className="text-4xl mb-16 w-[90%] font-[200] text-hymcBlue lg:w-[50%] lg:min-w-[450px]">
            More Articles
          </Link>
        </div>
      </div>
    </section>
  )
}

export default NewsPostContent
