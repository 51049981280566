import { Link } from 'gatsby'
import React from 'react'
import { GatsbyImage,getImage } from 'gatsby-plugin-image'

function SingleMorePostImage({postUrl,background,title,isFeatured}) {
  return (
    <Link
      to={`/blog/${postUrl}`}
      className={`relative flex h-[35vh] min-w-[450px] max-w-[25%] grow basis-1/4 items-end justify-center bg-cover bg-center bg-no-repeat`}
    >
      <GatsbyImage
        image={getImage(background.localFile)}
        alt={"test alt"}
        className="bg-image"
      />
      <div
        className="overlay"
        style={{
          backgroundImage: `linear-gradient(0deg, #015794 0%, rgba(1, 87, 148, 0) 61.45%)`,
        }}
      ></div>

      <h4 className=" z-10 px-8 py-4 font-[300] text-white">{title}</h4>
    </Link>
  );
}

export default SingleMorePostImage