import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react'
import SingleMorePostImage from './SingleMorePostImage';

const BlogPostContent = ({ data }) => {
  const curPostTitle = data.strapiPost.Title;
  const {
    strapiPost: { Builder, FeaturedImage, FeaturedImageMobile },
  } = data;

  return (
    <section>
      <div className="posts-page h-fit">
        <div className="posts-hero-section section from-13% to-72% relative flex max-h-[80vh] bg-gradient-to-b from-gradientStart to-gradientEnd">
          {FeaturedImage && (
            <GatsbyImage
              image={getImage(FeaturedImage.localFile)}
              alt={data.strapiPost.Title || ""}
              className={`${FeaturedImageMobile ? "dsk" : ""}`}
              objectFit="contain"
            />
          )}
          {FeaturedImageMobile && (
            <GatsbyImage
              image={getImage(FeaturedImageMobile.localFile)}
              alt={data.strapiPost.Title || ""}
              className={`${FeaturedImage ? "mb" : ""}`}
              objectFit="contain"
            />
          )}
        </div>
        <div className="quote-section section flex h-fit min-h-screen flex-col items-center justify-center px-6 pt-20 pb-28 lg:pt-36">
          <div className="quote-section-contents flex max-w-[1400px] flex-col items-center justify-between">
            <h2 className="title mb-8 leading-tight text-hymcBlue lg:min-w-[450px]">
              {data.strapiPost.Title}
            </h2>
            <div className="body-text builder flex flex-wrap items-center justify-center gap-x-8 gap-y-10 lg:min-w-[450px]">
              {Builder &&
                Builder?.map((section) => {
                  switch (section.strapi_component) {
                    case "builder.text":
                      return (
                        <div
                          className="text-section leading-2 flex flex-col gap-y-4"
                          dangerouslySetInnerHTML={{
                            __html:
                              section?.Text?.data?.childMarkdownRemark?.html,
                          }}
                        />
                      );
                    case "builder.image":
                      return (
                        <GatsbyImage
                          image={getImage(section?.Image?.localFile)}
                          alt={"Post image"}
                          className="image-section max-h-[500px]"
                          objectFit="contain"
                        />
                      );
                    default:
                      return null;
                  }
                })}
            </div>
          </div>
        </div>
        {data.allStrapiPost.nodes.length > 1 && (
          <div className="more-articles flex flex-col items-center justify-center">
            <h2 className="mb-16 w-[90%] font-[200] font-[40px] text-hymcBlue lg:w-[50%] lg:min-w-[450px]">
              More Articles
            </h2>
            <div className="articles bottom-clip flex min-w-full flex-wrap bg-hymcBlue pb-[25vh]">
              {data.allStrapiPost.nodes.map((node, index) => {
                if (node.Title !== curPostTitle) {
                  return (
                    <SingleMorePostImage
                      postUrl={node.slug}
                      background={node.FeaturedImage}
                      title={node.Title}
                      isFeatured={node.isFeatured}
                      columns={"4"}
                      key={index}
                    />
                  );
                }
                return null;
              })}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default BlogPostContent
